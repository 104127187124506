


















































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { GetSizhen, GetSizhenDoctor } from "@/request/manage";
import { GetFilterOption } from "@/request/differences";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private type: any;
  @Prop()
  private title: any;
  @Prop()
  private visible: any;
  @Watch("visible", { immediate: true })
  private visibleChange() {
    this.ifShow = true;
    if (this.visible) {
      this.getData();
    }
  }
  @Prop()
  private curData: any;
  @Watch("curData", { immediate: true })
  private curDataChange() {
    if (this.curData) {
      this.selectData = JSON.parse(JSON.stringify(this.curData));
    }
  }
  private ifShow: any = false;
  private jigous: any = [];
  private zhanghus: any = [];
  private search1: any = "";
  private search2: any = "";
  private currentJigou: any = {};
  private get user() {
    return this.$store.state.user;
  }
  private delOneSizhen() {}
  private ifChecked(items: any) {
    let result: any = false;
    const index = this.selectData.findIndex((val: any) => {
      return items.account_id === val.account_id;
    });
    if (index != -1) {
      result = true;
    }
    return result;
  }
  private selectData: any = [
    // {
    //   account_id: "13480",
    //   doctor_id: "doctor_id_13480",
    //   hospital_id: "hospital_id_1",
    // },
  ];
  private selectJigou(item: any) {
    this.currentJigou = item;
    this.getZhanghus();
  }
  private getResultArr() {
    let arr: any = [];
  }
  private selectZhanghu(d: any) {
    const index = this.selectData.findIndex((val: any) => {
      return d.account_id === val.account_id;
    });
    if (index == -1) {
      this.selectData.push(d);
    } else {
      this.selectData.splice(index, 1);
    }
  }
  private makeSure() {
    this.$emit("update", this.selectData);
  }
  private getJigous(val?: any) {
    this.currentJigou = {};
    this.zhanghus = [];
    if (this.type == "数据看板") {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const params = {
        search: val || "",
        type: "机构",
      };
      GetFilterOption(this, params, loading)
        .then((res: any) => {
          loading.close();
          res.forEach((ele: any) => {
            ele.hospital_id = ele._id;
          });
          this.jigous = res;
        })
        .catch(() => {
          loading.close();
        });
    } else {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const params = {
        params: {
          search: val || "",
        },
      };
      GetSizhen(this, params, loading)
        .then((res: any) => {
          loading.close();
          this.jigous = res;
        })
        .catch(() => {
          loading.close();
        });
    }
  }
  private getZhanghus(val?: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    let params: any = {
      hospital_ids: [this.currentJigou.hospital_id],
      search: val || "",
    };
    if (this.type == "数据看板") {
      params.type = "机构";
    }
    GetSizhenDoctor(this, params, loading)
      .then((res: any) => {
        this.zhanghus = res;
        loading.close();
      })
      .catch(() => {
        loading.close();
      });
  }
  private getData() {
    this.getJigous();
  }
  private cancel() {
    this.$emit("cancel", false);
  }
}
